<template>
    <div>
        <!-- Header: Personal Info -->
        <div class="d-flex">
            <h4 class="mb-0 ">Payment Account Information</h4>
        </div>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-overlay :show='loading' spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        
                        <b-col cols="12">
                            <hr />
                        </b-col>
                        <!--Field: Account Nickname -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Account Nickname" rules="required">
                                <b-form-group class="required" label="Account Nickname" label-for="description" label-cols-md="4">
                                    <b-form-input id="description" :state="getValidationState(validationContext)" v-model="paymentaccountData.description" placeholder="Account Nickname is a way to easily identify the account in invoicing program"/>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!--Field: Bank Name (ENG) -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Bank Name (ENG)" rules="required">
                                <b-form-group class="required" label="Bank Name (ENG)" label-for="bank_name_en" label-cols-md="4">
                                    <b-form-input id="bank_name_en" :state="getValidationState(validationContext)" v-model="paymentaccountData.bank_name_en" placeholder="eg. Hang Seng Bank Limited"/>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!--Field: Bank Name (CN) -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Bank Name (CN)" rules="required">
                                <b-form-group class="required" label="Bank Name (CN)" label-for="bank_name_cn" label-cols-md="4">
                                    <b-form-input id="bank_name_cn" :state="getValidationState(validationContext)" v-model="paymentaccountData.bank_name_cn" placeholder="eg. 恆生銀行" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!--Field: Bank Account -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Bank Account" rules="required">
                                <b-form-group class="required" label="Bank Account" label-for="bank_account" label-cols-md="4">
                                    <b-form-input id="bank_account" :state="getValidationState(validationContext)" v-model="paymentaccountData.bank_account" placeholder="eg. 264-303967-001"/>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                </b-row>
                <b-col cols="12">
                    <hr />
                </b-col>

                <!-- Remarks Loop -->
                    <b-row v-for="(item, index) in paymentaccountData.payment_remarks" :id="item.id" :key="item.id" ref="row" style="margin:1px; width:100%;">
                        <!-- Remark CN -->
                        <b-col md="5">
                            <b-form-group label="Remark CN">
                                <b-form-input type="text" placeholder="Remark CN" v-model="item.remark_cn" />
                            </b-form-group>
                        </b-col>
    
                        <!-- Remark EN -->
                        <b-col md="5">
                            <b-form-group label="Remark EN">
                                <b-input-group class="mb-3">
                                    <b-form-input type="text" placeholder="Remark EN" v-model="item.remark_en" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
    
                        <!-- Remove Button -->
                        <b-col md="2" class="mb-50">
                            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span>Delete</span>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-col cols="12">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>Add New Remark</span>
                        </b-button>
                    </b-col>
                <b-col cols="12">
                    <hr />
                </b-col>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                        {{button_text}}
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
                        Cancel
                    </b-button>
                </div>
            </b-form>
            </b-overlay>
        </validation-observer>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import 'animate.css'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js"
import { heightTransition } from '@core/mixins/ui/transition'

export default {
    directives: {
        Ripple,
    },
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        flatPickr,
        BFormInput,
        vSelect,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        BFormCheckbox,
        BFormDatepicker,
        BInputGroupAppend,
        BInputGroup,
        BFormInvalidFeedback,
        BOverlay,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    methods: {
        hide(){
            this.$router.replace('/invoices/paymentaccounts/list')
        },
        removeItem(index) {
            this.paymentaccountData.payment_remarks.splice(index, 1)
        },
        repeateAgain() {
            this.paymentaccountData.payment_remarks.push({
                id: this.paymentaccountData.payment_remarks.length,
            })
        },
        onSubmit(){
            console.log('onSubmit')
            this.$swal({
                title: '確認儲存?',
                showCancelButton: true,
                confirmButtonText: '儲存',
                cancelButtonText: '取消',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            })
            .then(result => {
                if (result.value) {
                    this.loading = true
                    store.dispatch("invoice/addPaymentAccount", this.paymentaccountData)
                        .then((response) => {
                            this.loading = false
                            this.$swal({
                                text: response.data.message,
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__bounceIn',
                                },
                                buttonsStyling: false,
                            })
                            this.$emit('refetch-data')
                            if (this.clientData.id == 0) {
                                // Return to client list
                                this.hide()
                            }
                        }).catch((error) => {
                            this.loading = false
                            this.$swal({
                                text: JSON.stringify(error.response.data.message),
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__bounceIn',
                                },
                                buttonsStyling: false,
                            })
                        })
                }
            })
        }
    },
    props: {
        paymentaccountData: {
            type: Object,
            required: true,
        }
    },
    setup(props, { emit }) {
        const loading = ref(false)
        const button_text = ref('Add')

        const resetpaymentaccountData = () => {
            props.paymentaccountData = JSON.parse(JSON.stringify({}))
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetpaymentaccountData)

        const checkEdit = () => {
            if (props.paymentaccountData.id > 0) {
                button_text.value = 'Edit'
            } else {
                button_text.value = 'Add'
            }
        }

        checkEdit()

        watch(() => props.paymentaccountData, (selection, prevSelection) => {
            checkEdit()
        })

        return {
            button_text,
            refFormObserver,
            getValidationState,
            resetForm,
            loading,
        }

    }
}

</script>