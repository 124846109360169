<template>
    <component :is="paymentaccountData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="paymentaccountData === undefined">
            <h4 class="alert-heading">Error fetching payment account data</h4>
            <div class="alert-body">
                No payment account found with this id. Check
                <b-link class="alert-link" :to="{ name: 'paymentaccounts-list' }">
                    Payment Account List
                </b-link>
                for other payment account.
            </div>
        </b-alert>
        <payment-account-edit-tab-information v-if="paymentaccountData" :paymentaccount-data="paymentaccountData"  @refetch-data="refetchData" class="mt-2 pt-75" />
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import invoiceStoreModule from "../invoiceStoreModule";
import PaymentAccountEditTabInformation from "./PaymentAccountEditTabInformation.vue";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        // PaymentAccountEditTabAccount,
        PaymentAccountEditTabInformation,
    },
    setup() {
        const paymentaccountData = ref({});

        const INVOICE_STORE_MODULE_NAME = "invoice";

        const paymentaccount = {
            id: 0,
            description: '',
            bank_name_en: '',
            bank_name_cn: '',
            bank_account: '',
            payment_remarks: [{
                id: 0,
                remark_cn: '',
                remark_en: '',
            }]
        }


        // Register module
        if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
            store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);


        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_STORE_MODULE_NAME))
                store.unregisterModule(INVOICE_STORE_MODULE_NAME);
        });

        const refetchData = () => {
            if (router.currentRoute.name == 'paymentaccounts-create') {
                paymentaccountData.value = paymentaccount
            } else {
                store
                    .dispatch(INVOICE_STORE_MODULE_NAME+"/fetchPaymentAccount", { id: router.currentRoute.params.id })
                    .then((response) => {
                        paymentaccountData.value = response.data.paymentaccount;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            paymentaccountData.value = undefined;
                        }
                    });
            }
        };

        refetchData()

        return {
            paymentaccountData,
            refetchData,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
